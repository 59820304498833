<template>
  <router-view v-slot="{ Component }" :key="$route.fullPath">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <component v-if="!$route.meta.keepAlive" :is="Component" />
  </router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>

</style>
